<template>
<div
    :style="borderStyle"
>
  <!--  Order Number-->
  <v-sheet
      v-if="review && !bordered"
      :color="QUESTION_HAS_CORRECT_REPLY(question) ? this.wsSUCCESS : this.wsWARNING"
      class="mb-5 px-4 py-2 "
      style="border-radius: 8px"
      dark

  >
    <h5 class="d-flex align-center">
      <v-icon class="mr-2">{{ QUESTION_HAS_CORRECT_REPLY(question)  ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
      {{ $t(QUESTION_HAS_CORRECT_REPLY(question)  ? 'QuestionCorrect' : 'QuestionIncorrect') }}
    </h5>
  </v-sheet>

  <h4 v-if="orderNumber" class="wsDARKER d-flex justify-space-between align-center">
    {{ $t('Question')}} {{ orderNumber }}

    <v-icon
        v-if="review && bordered"
        :color="QUESTION_HAS_CORRECT_REPLY(question) ? this.wsSUCCESS : this.wsWARNING" class="mr-2"
    >
      {{ QUESTION_HAS_CORRECT_REPLY(question)  ? 'mdi-check-circle' : 'mdi-close-circle' }}
    </v-icon>

  </h4>

  <!--  Question Content -->
  <ws-text-viewer
      :value="question.content"
      :html="question.content_text"
      :course-id="courseId"
      :test-id="testId"
      student
      class="pb-6"
  />

  <h4 class="wsDARKER mb-6">{{ $t('Answers') }}:</h4>
  <!-- Matched Question  Answers -->
  <matched-answers-viewer
      v-if="question.type === 'match'"
      @answer="handleAnswer($event)"
      @reset-answer="$emit('reset-answer' , $event)"
      :answers="question.answers"
      :question="question"
      :review="review"
      :preview="preview"

  />

  <!-- Regular Answers -->
  <template v-else-if="question.type !== 'true_false' ">

    <v-sheet
        @click="handleAnswer(answer)"
        v-for="(answer,j) in question.answers" :key="j"
        :style="getBorderColor(answer)"
        style="border-radius: 8px"
        class="pa-3 d-flex justify-space-between align-center mb-3"
        :class="[{pointer : !preview && !review}]"
    >
      <ws-text-viewer
          :value="answer.content"
          :html="answer.content_text"
          :test-id="testId"
          student
          no-padding
      />

      <v-icon :color="getAnswerIconColor(answer)">
        {{ getAnswerIcon(answer) }}
      </v-icon>

    </v-sheet>

  </template>


</div>
</template>

<script>
import matchedAnswersViewer from "@/components/pages/westudy/tests/UI/matchedAnswersViewer.vue";
import tests from "@/mixins/tests";
export default {
  name: "questionViewer",
  mixins : [tests],
  components : {
    matchedAnswersViewer
  },
  props : {
    question : {
      type : Object,
      default() { return {} }
    },
    orderNumber : {
      type : Number
    },
    courseId : {
      type : String
    },
    testId : {
      type : String
    },
    preview : {
      type : Boolean,
      default : false
    },
    review : {
      type : Boolean,
      default : false
    },
    bordered : {
      type : Boolean,
      default : false
    }
  },
  computed : {
    borderStyle() {
      if (!this.bordered) {
        return ''
      }

      let color = this.wsBACKGROUND
      let style =  `padding : 12px;`
          style += `border-radius : 8px;`
          style += `margin-bottom : 20px;`

      if (this.review) {
        color = this.QUESTION_HAS_CORRECT_REPLY(this.question) ? this.wsSUCCESS : this.wsWARNING
      }


      style +=  `border: 1px solid ${color};`

      return style

    }
  },
  methods : {

    handleAnswer(answer) {
      if (this.review || this.preview) {
        return
      }
      this.$emit('answer', answer)
    },
    getBorderColor(answer) {
      let borderColor = answer.reply ? this.wsACCENT : this.wsBACKGROUND

      if ( this.review && answer.reply  ) {
        borderColor =  answer.reply && answer.correct ? this.wsSUCCESS : this.wsWARNING
      }

      if ( this.review && answer.correct  ) {
        borderColor = this.wsSUCCESS
      }

      return `border: 1px solid ${borderColor}`
    },
    getAnswerIconColor(answer) {

      if ( this.review && answer.correct  ) {
        return this.wsSUCCESS
      }

      if ( this.review && answer.reply  ) {
        return answer.reply && answer.correct ? this.wsSUCCESS : this.wsWARNING
      }
      if ( answer.reply) {
        return this.wsACCENT
      }
      return this.wsDARKLIGHT
    },
    getAnswerIcon(answer) {

      if ( this.review && answer.correct && !answer.reply  ) {
        return 'mdi-check-circle-outline'
      }

      if ( this.review && answer.reply  ) {
        return answer.reply && answer.correct ? 'mdi-check-circle' : 'mdi-close-circle'
      }

      if ( answer.reply) {
        return 'mdi-radiobox-marked'
      }

      return 'mdi-radiobox-blank'
    },
  }
}
</script>

<style scoped>

</style>