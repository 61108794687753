export default {
    computed : {
        QUESTION_TYPES_SELECT() {
            return [
                { text : this.$t('DefaultQuestion') , value : 'default'   },
                { text : this.$t('MatchQuestion')   , value : 'match'     },
                // { text : 'True/False Question' , value : 'true_false' }
            ]
        },
    },
    methods : {
        QUESTION_HAS_REPLY(question , preview = false) {

            if ( preview ) {
                return this.QUESTION_HAS_CORRECT_REPLY(question)
            }

            if (question.type === 'default' ) {
                let hasAnswer = question.answers.find( el=> el.reply === true )
                return  !!hasAnswer
            }
            if (question.type === 'true_false' ) {
                return  question.question_reply_bool !== null
            }
            if (question.type === 'match' ) {
                let hasAnswer = question.answers.filter( el=> !!el.reply && !!el.matched_reply )
                return  hasAnswer.length === question.answers.length/2
            }

            return false
        },
        QUESTION_HAS_CORRECT_REPLY(question) {

            if (question.type === 'default' && !question.multiple_type ) {
                let hasAnswer = question.answers.find( el=> el.reply === true )
                if ( !hasAnswer) {
                    return false
                }
                return  !!hasAnswer.correct
            } else if ( question.type === 'default' && question.multiple_type ) {
                let correctAnswersUuids = question.answers.filter( el=> el.correct === true ).map(el => el.uuid)
                let studentReplies = question.answers.filter( el=> el.reply === true ).map(el => el.uuid)
                if ( studentReplies.length !== correctAnswersUuids.length ) {
                    return false
                }

                return  studentReplies.filter(el => correctAnswersUuids.includes(el) ).length === correctAnswersUuids.length
            }
            else if (question.type === 'true_false' ) {
                return  question.question_reply_bool !== null
            } else if (question.type === 'match' ) {
                let replies = 0
                question.answers.forEach( answer => {
                    if ( answer.matched_reply && answer.correct) {
                        replies++
                    }
                })
                return replies === question.answers.length/2
            }

            return  false
        }
    }
}

